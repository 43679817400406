<template>
  <div>
    <b-overlay :show="show" rounded="sm" no-fade>
      <b-card-code title="Informasi Data Pemasukan">
        <b-row class="mt-2 mb-2" align="end">
          <b-col align="start" md="4"> </b-col>

          <b-col md="5"> </b-col>
          <!-- spasi -->
          <b-col align="end" md="3">
            <h4>
              Total: Rp.
              {{
                this.total_amount
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
              }}
            </h4>
          </b-col>
        </b-row>

        <b-row class="mt-2 mb-2" align="end">
          <b-col align="start" md="4">
            <v-select
              v-model="kavling_id"
              :options="kavlingOptions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :reduce="(_id) => _id._id"
              placeholder="Pilih Kavling"
              @input="changeStatus()"
            />
          </b-col>

          <b-col md="5"> </b-col>
          <!-- spasi -->
          <b-col align="end" md="3">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="showModalTambah()"
            >
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle">Tambah Pemasukan</span>
            </b-button>
          </b-col>
        </b-row>

        <b-row class="mt-2 mb-2" align="end">
          <b-col md="3">
            <b-form-input
              id="name"
              v-model="description"
              placeholder="Cari Keterangan"
              @input="changeStatus()"
            />
          </b-col>
          <b-col md="3">
            <flat-pickr
              v-model="date"
              class="form-control"
              placeholder="Pilih Tanggal"
              :config="configs"
              @input="changeStatus()"
            />
          </b-col>
          <b-col md="2" align="start">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              @click="resetFilter()"
            >
              Reset
            </b-button>
          </b-col>

          <b-col md="4" align="end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              @click="downloadFilter()"
            >
              <feather-icon icon="ArrowDownIcon" class="mr-50" />
              <span class="align-middle">Download</span>
            </b-button>
          </b-col>
        </b-row>

        <!-- table -->
        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: false,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
          }"
          @on-sort-change="onSortChange"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'date'">
              {{ props.row.date.substring(0, 10) }}
            </span>

            <span v-else-if="props.column.field === 'lineNumber'">
              {{ paging.page * 10 + (props.index + 1) }}.
            </span>

            <span v-else-if="props.column.field === 'payment_amount'">
              Rp.
              {{
                props.row.payment_amount
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
              }}
            </span>

            <span v-else-if="props.column.field === 'action'">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                v-b-tooltip.hover.v-primary
                title="Detail"
                class="btn-icon mr-2"
                @click="showModalDetail(props)"
              >
                <feather-icon icon="SearchIcon" class="cursor-pointer" />
              </b-button>

              <b-button
                v-if="props.row.ownership_id === null"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                v-b-tooltip.hover.v-warning
                title="Ubah"
                class="btn-icon mr-2"
                @click="showModalUbah(props)"
              >
                <feather-icon icon="EditIcon" class="cursor-pointer" />
              </b-button>

              <b-button
                v-if="props.row.ownership_id != null"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                v-b-tooltip.hover.v-warning
                title="Ubah"
                class="btn-icon mr-2"
                @click="showModalUbahNone(props)"
              >
                <feather-icon icon="EditIcon" class="cursor-pointer" />
              </b-button>

              <b-button
                v-if="props.row.ownership_id === null"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                v-b-tooltip.hover.v-danger
                title="Hapus"
                class="btn-icon"
                @click="deleteInfo(props)"
              >
                <feather-icon icon="Trash2Icon" class="cursor-pointer" />
              </b-button>

              <b-button
                v-if="props.row.ownership_id != null"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                v-b-tooltip.hover.v-danger
                title="Hapus"
                class="btn-icon"
                @click="deleteNone(props)"
              >
                <feather-icon icon="Trash2Icon" class="cursor-pointer" />
              </b-button>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->

          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="paging.size"
                  :options="paging.perPageOptions"
                  class="mx-1"
                  @input="handlePageChange"
                />
                <span class="text-nowrap">
                  of {{ paging.total_elements }} entries
                </span>
              </div>

              <div>
                <b-pagination
                  :value="paging.page + 1"
                  :total-rows="paging.total_elements"
                  :per-page="paging.size"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>

        <!-- modal form Form Blok-->
        <b-modal
          id="modal-detail-blok"
          ref="ref-data-form-pemasukan"
          :title="formTitle"
          hide-footer="true"
          size="lg"
          no-close-on-backdrop
        >
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col md="12">
                  <b-form-group>
                    <template v-slot:label v-if="this.aksi != 'detailData'">
                      Tanggal Pemasukan <span class="text-danger">*</span>
                    </template>
                    <template v-slot:label v-else> Tanggal Pemasukan </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Tanggal"
                      rules="required"
                    >
                      <flat-pickr
                        v-model="formSubmit.date"
                        class="form-control"
                        placeholder="Pilih Tanggal"
                        :config="configs.allowInput"
                        :disabled="disableTrue"
                      />
                      <small class="text-danger">{{
                        errors[0] ? "Tanggal Wajib Diisi" : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label-for="mc-country">
                    <template v-slot:label v-if="this.aksi != 'detailData'">
                      Nama Pembayaran <span class="text-danger">*</span>
                    </template>
                    <template v-slot:label v-else> Nama Pembayaran </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Keterangan"
                      rules="required"
                    >
                      <b-form-textarea
                        rows="3"
                        v-model="formSubmit.payment_name"
                        placeholder="Masukkan Keterangan"
                        :disabled="disableTrue"
                      />
                      <small class="text-danger">{{
                        errors[0] ? "Keterangan Wajib Diisi" : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label-for="name">
                    <template v-slot:label v-if="this.aksi != 'detailData'">
                      Jumlah Pemasukan <span class="text-danger">*</span>
                    </template>
                    <template v-slot:label v-else> Jumlah Pemasukan </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Jumlah"
                      rules="required"
                    >
                      <b-form-input
                        id="name"
                        v-model="formSubmit.payment_amount"
                        placeholder="Masukkan Jumlah"
                        type="number"
                        :disabled="disableTrue"
                        @input="handleInputHarga"
                      />
                      <small class="text-danger">{{
                        errors[0] ? "Jumlah Wajib Diisi" : ""
                      }}</small>

                      <small v-if="formSubmit.payment_amount">
                        Rp. {{ price2 }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label-for="name">
                    <template v-slot:label v-if="this.aksi != 'detailData'">
                      Nama Pembayar <span class="text-danger">*</span>
                    </template>
                    <template v-slot:label v-else> Nama Pembayar </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Nama Pembayar"
                      rules="required"
                    >
                      <b-form-input
                        id="name"
                        v-model="formSubmit.payer_name"
                        placeholder="Masukkan Nama Pembayar"
                        :disabled="disableTrue"
                      />
                      <small class="text-danger">{{
                        errors[0] ? "Nama Pembayar Wajib Diisi" : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>

            <b-row class="mt-3 mb-2" v-if="this.aksi != 'detailData'">
              <b-col align="end">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="secondary"
                  @click="kembaliModalForm()"
                  class="mr-2"
                >
                  Batal
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click.prevent="submitValidate"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>

            <b-row class="mt-3 mb-2" v-else>
              <b-col align="end">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="secondary"
                  @click="kembaliModalForm()"
                  class="mr-2"
                >
                  Kembali
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-modal>
        <!-- end modal form form kavling -->
      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import axios from "@axios";
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BPagination,
  BFormFile,
  BOverlay,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BMedia,
  BAvatar,
  BMediaBody,
  BMediaAside,
  BAvatarGroup,
  BImg,
  BInputGroupPrepend,
  BCard,
  BCardText,
  BCardBody,
  BDropdownItem,
  BDropdown,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

export default {
  components: {
    BCardCode,
    VBTooltip,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BPagination,
    VueGoodTable,
    BFormFile,
    BOverlay,
    vSelect,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BImg,
    BInputGroupPrepend,
    BCard,
    BCardBody,
    BCardText,
    BDropdownItem,
    BDropdown,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      //here
      file: null,

      required,
      email,

      price2: "",

      log: [],
      dir: false,
      pages: ["5", "10", "20", "30"],

      paging: {
        page: 0,
        total_pages: 0,
        size: 10,
        total_elements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      columns: [
        {
          label: "No",
          field: "lineNumber",
          thClass: "text-center",
          tdClass: "text-center",
          width: "70px",
        },
        {
          label: "Tanggal",
          field: "date",
          thClass: "text-center",
        },
        {
          label: "Keterangan",
          field: "description",
          thClass: "text-center",
        },
        {
          label: "Jumlah",
          field: "payment_amount",
          thClass: "text-center",
        },
        {
          label: "Aksi",
          field: "action",
          thClass: "text-center",
          tdClass: "text-center",
          width: "200px",
        },
      ],
      rows: [],

      configs: {
        mode: "range",
        allowInput: {
          allowInput: true,
          dateFormat: "Y-m-d",
        },
      },

      kavlingOptions: [],

      // old delete
      searchTerm: "",

      date: "",
      description: "",
      kavling_id: "",

      sort: "",

      create_time: "",
      total_amount: "",

      selected: null,

      formTitle: "",
      aksi: "",
      id_income: "",
      disableTrue: false,

      formSubmit: {
        date: "",
        payment_amount: "",
        payment_name: "",
        payer_name: "",
      },
    };
  },

  beforeMount() {
    this.getData(this.paging.size, this.paging.page);
    this.getKavling();
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },

  created() {
    //here
  },

  methods: {
    getData(size, page) {
      this.show = true;

      let search0 = "";
      if (this.date) {
        search0 = "&date=" + this.date;
      } else {
        search0;
      }

      let search1 = "";
      if (this.description) {
        search1 = "&description=" + this.description;
      } else {
        search1;
      }

      let search2 = "";
      if (this.kavling_id) {
        search2 = "&kavling_id=" + this.kavling_id;
      } else {
        search2;
      }

      return new Promise(() => {
        axios
          .get(
            "v1/income/all?" +
              "page=" +
              page +
              "&size=" +
              size +
              "&sort_by=date&sort_dir=-1" +
              search0 +
              search1 +
              search2
          )
          .then((res) => {
            this.rows = res.data.content;

            this.total_amount = res.data.total_amount;

            this.paging.page = res.data.page;
            this.paging.size = res.data.size;
            this.paging.total_elements = res.data.total_elements;
            this.paging.total_pages = res.data.total_pages;

            this.show = false;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });
    },

    submitValidate() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.aksi === "tambah") {
            return new Promise(() => {
              axios
                .post("v1/income", this.formSubmit)
                .then((res) => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Tambah Data Berhasil`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Tambah Data Pemasukan Berhasil`,
                    },
                  });

                  this.getData(this.paging.size, this.paging.page);
                  this.$refs["ref-data-form-pemasukan"].hide();
                })
                .catch((error) => {
                  if (error.response.status == 401) {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                    this.$router.push("/login");
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                  }
                });
            });
          } else {
            return new Promise(() => {
              axios
                .put("v1/income/" + this.id_income, this.formSubmit)
                .then((res) => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Ubah Data Berhasil`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Ubah Data Pemasukan Berhasil`,
                    },
                  });

                  this.getData(this.paging.size, this.paging.page);
                  this.$refs["ref-data-form-pemasukan"].hide();
                })
                .catch((error) => {
                  if (error.response.status == 401) {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                    this.$router.push("/login");
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                  }
                });
            });
          }
        }
      });
    },

    showModalTambah() {
      this.formSubmit.date = "";
      this.formSubmit.payment_amount = "";
      this.formSubmit.payment_name = "";
      this.formSubmit.payer_name = "";

      this.disableTrue = false;
      this.aksi = "tambah";
      this.formTitle = "Form Tambah Pemasukan";

      this.$refs["ref-data-form-pemasukan"].show();
    },

    showModalUbah(props) {
      this.id_income = props.row._id;
      this.formSubmit.date = props.row.date.substring(0, 10);
      this.formSubmit.payment_amount = props.row.payment_amount;
      this.formSubmit.payment_name = props.row.payment_name;
      this.formSubmit.payer_name = props.row.payer_name;

      this.handleInputHarga();

      this.disableTrue = false;
      this.aksi = "ubah";
      this.formTitle = "Form Update Pemasukan";

      this.$refs["ref-data-form-pemasukan"].show();
    },

    showModalUbahNone(props) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: "Tidak dapat mengubah pemasukan",
          icon: "XCircleIcon",
          variant: "danger",
        },
      });
    },

    showModalDetail(props) {
      this.formSubmit.date = props.row.date;
      this.formSubmit.payment_amount = props.row.payment_amount;
      this.formSubmit.payment_name = props.row.payment_name;
      this.formSubmit.payer_name = props.row.payer_name;

      this.handleInputHarga();

      this.disableTrue = true;
      this.aksi = "detailData";
      this.formTitle = "Form Detail Pemasukan";

      this.$refs["ref-data-form-pemasukan"].show();
    },

    kembaliModalForm() {
      this.id_income = "";
      this.formSubmit.date = "";
      this.formSubmit.payment_amount = "";
      this.formSubmit.payment_name = "";
      this.formSubmit.payer_name = "";

      this.$refs["ref-data-form-pemasukan"].hide();
      this.show = false;
    },

    changeStatus() {
      const page = (this.paging.page = 0);
      this.getData(this.paging.size, page);
    },

    getKavling() {
      return new Promise(() => {
        axios.get("v1/kavling/combo").then((res) => {
          this.kavlingOptions = res.data;
        });
      });
    },

    downloadFilter() {
      let search0 = "";
      if (this.date) {
        search0 = "&date=" + this.date;
      } else {
        search0;
      }

      let search1 = "";
      if (this.description) {
        search1 = "&description=" + this.description;
      } else {
        search1;
      }

      let search2 = "";
      if (this.kavling_id) {
        search2 = "&kavling_id=" + this.kavling_id;
      } else {
        search2;
      }

      window.open(
        "https://api-bsgproperti.mrizkyff.com/v1/income/download?page=" +
          this.paging.page +
          "&size=" +
          this.paging.size +
          "&sort_by=date&sort_dir=-1" +
          search0 +
          search1 +
          search2
      );
    },

    deleteNone(props) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: "Tidak dapat menghapus pemasukan",
          icon: "XCircleIcon",
          variant: "danger",
        },
      });
    },

    deleteInfo(props) {
      this.$swal({
        title: "Apakah Anda Yakin ?",
        text: "Data yang terhapus tidak dapat dikembalikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus!",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteApi(props);
        }
      });
    },

    deleteApi(props) {
      return new Promise(() => {
        axios
          .delete("v1/income/" + props.row._id)
          .then((res) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Hapus Data Berhasil`,
                icon: "CheckCircleIcon",
                variant: "success",
                text: `Hapus Data Pemasukan Berhasil`,
              },
            });
            this.getData(this.paging.size, this.paging.page);
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });
    },

    resetFilter() {
      this.date = "";
      this.description = "";
      this.kavling_id = "";
      const page = (this.paging.page = 0);
      this.getData(this.paging.size, page);
    },

    format2: (value) =>
      (value + "").replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, "."),

    handleInputHarga() {
      this.price2 = this.format2(this.formSubmit.payment_amount);
      this.$emit("input", (this.price2 + "").replace(/[^0-9]/g, ""));
    },

    handleSearch(searching) {
      const page = (this.paging.page = 0);
      this.getData(this.paging.size, page);
    },
    handleChangePage(value) {
      const page = value - 1;
      this.getData(this.paging.size, page);
    },
    handlePageChange(active) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },
    onSortChange(params) {
      this.sort = `${params[0].field}`;
      this.getData(this.paging.size, this.paging.page);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
